export const PurchaseState = {
  Init: "init",
  Transaction: "transaction",
  EnqueueSubscription: "schedule create subscription",
  Subscription: "subscription",
  Success: "success",
  Redemption: "redemption",
  Suspended: "subscription suspension",
  Cancelled: "subscription cancelled",
  Refund: "purchase refunded",
  Modified: "modified subscription",
  DeltaTransaction: "delta transaction",
  ModifySubscription: "update subscription",
  UpdateCreditCard: "update credit card",
}

export const SuspensionState = {
  Expired: "The current card has expired.",
  Invalid: "The current card is invalid.", // no billing name
  UpcomingExpiry: "The current card expires before the next charge.",
  Declined: "Your last payment was declined.",
}

export const SuspensionErrorCodes = new Map([
  ["E00013", SuspensionState.Expired],
  ["E00014", SuspensionState.Invalid],
  ["E00018", SuspensionState.UpcomingExpiry],
  ["E00040", SuspensionState.Invalid],
])

export const TxnState = {
  Init: "init",
  Charge: "charge",
  Credits: "credits",
  RentBundle: "rentBundle",
  RentVideo: "rentVideo",
  CreateLoveNoteComment: "createLoveNoteComment",
  Success: "success",
  Declined: "declined",
  Redemption: "redemption",
  Refunded: "refunded",
}
